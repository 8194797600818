import React from 'react'
import { graphql } from 'gatsby'

// SLider
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

// Gatsby
import Img from 'gatsby-image'

// Styles
import {
	Hero,
	HeaderWrap,
	Content,
	Header,
	DataTitle,
	DataItem,
	ImgWrap,
	DataValue,
	ShortDescription,
	DataWrap,
	SingleContent,
	SingleFlexibleContent,
	ImageLeftContent,
	ImageRightContent,
} from './style'

// Components
import Layout from '../components/layout'
import SEO from '../components/seo'
import Container from '../components/ui/Container'
import Heading from '../components/ui/Heading'
import Wysiwyg from '../components/ui/Wysiwyg'
import Paragraph from '../components/ui/Paragraph'
import ImageRightContentCta from '../components/ui/ImageRightContentCta'
import ImageLeftContentCta from '../components/ui/ImageLeftContentCta'
import Video from '../components/ui/Video'
import Testemonial from '../components/ui/Testemonial'

const Project = React.memo((props) => {
	const {
		Type,
		Title,
		Client,
		Text,
		Year,
		Tags,
		Seo,
		Fields,
		FeaturedImage,
		GradientDark,
		GradientLight,
	} = props.data.timacum.project
	const { next } = props.pageContext

	// Slick slider
	const settings = {
		dots: true,
		infinite: false,
		speed: 500,
		slidesToShow: 1.3,
		slidesToScroll: 1,
		// slidesPerRow: 1.1,
	}

	return (
		<Layout nextProject={next} location={props.location}>
			<SEO data={Seo} />
			<Hero gradientdark={GradientDark} gradientlight={GradientLight}>
				<Container>
					<HeaderWrap>
						<Content>
							<Header as={Heading} level={1}>
								{Title}
							</Header>
							<DataTitle>{Type === 'website' ? 'Client' : 'Product'}</DataTitle>
							<DataValue>{Client}</DataValue>
							<ShortDescription as={Wysiwyg}>{Text}</ShortDescription>
							<DataWrap>
								<DataItem>
									<DataTitle>Year</DataTitle>
									<DataValue>{Year}</DataValue>
								</DataItem>
								<DataItem>
									<DataTitle>Work</DataTitle>
									<DataValue>
										{Tags.map((tag, index) =>
											Tags.length - 1 === index ? tag.Title : tag.Title + '/ '
										)}
									</DataValue>
								</DataItem>
							</DataWrap>
						</Content>
						<ImgWrap>
							<img
								loading="lazy"
								src={FeaturedImage.imageFile.publicURL}
								alt={FeaturedImage.alternativeText}
								title={FeaturedImage.caption}
							/>
						</ImgWrap>
					</HeaderWrap>
				</Container>
			</Hero>
			<SingleContent as={Container}>
				<SingleFlexibleContent>
					{Fields.map((field, index) => {
						if (field.Layout === 'title-text') {
							return (
								<div key={index} className="titleText">
									{field.Title ? (
										<Header as={Heading} level={2}>
											{field.Title}
										</Header>
									) : null}
									{field.Text ? (
										/\r|\n/.exec(field.Text) ? (
											<Wysiwyg>{field.Text}</Wysiwyg>
										) : (
											<Paragraph>{field.Text}</Paragraph>
										)
									) : null}
								</div>
							)
						} else if (field.Layout === 'image') {
							return (
								<div
									key={index + 'image'}
									className={
										field.HaveMobileSlider
											? 'singleImage h-slider'
											: 'singleImage'
									}
								>
									<Img
										className={field.SingleDropShadow ? 'dropShadow ' : ''}
										fluid={field.Image.imageFile.childImageSharp.fluid}
										alt={field.Image.alternativeText}
										title={field.Image.caption}
									/>
								</div>
							)
						} else if (field.Layout === 'text') {
							return (
								<div key={index} className="singleText">
									{field.Text ? (
										/\r|\n/.exec(field.Text) ? (
											<Wysiwyg>{field.Text}</Wysiwyg>
										) : (
											<Paragraph>{field.Text}</Paragraph>
										)
									) : null}
								</div>
							)
						} else if (field.Layout === 'image-right-text') {
							return (
								<div key={index}>
									<ImageRightContent as={ImageRightContentCta} fields={field} />
								</div>
							)
						} else if (field.Layout === 'image-left-text') {
							return (
								<div key={index}>
									<ImageLeftContent as={ImageLeftContentCta} fields={field} />
								</div>
							)
						} else if (field.Layout === 'testemonial') {
							return (
								<div key={index} className="testemonial">
									<ImageRightContent as={Testemonial} fields={field} />
								</div>
							)
						} else if (field.Layout === 'video') {
							return (
								<div key={index}>
									<Video url={field.Link} />
								</div>
							)
						} else if (field.Layout === 'two-images') {
							let classes = 'twoImages '
							classes += field.DesktopMobilePreview ? 'bigAndSmall ' : ''
							return (
								<div key={index} className={classes}>
									{field.FirstImage.imageFile.childImageSharp === null ? (
										<img
											loading="lazy"
											className={field.DoubleDropShadow ? 'dropShadow ' : ''}
											src={field.FirstImage.imageFile.publicURL}
											alt={field.FirstImage.alternativeText}
											title={field.FirstImage.caption}
										/>
									) : (
										<Img
											className={field.DoubleDropShadow ? 'dropShadow ' : ''}
											fluid={field.FirstImage.imageFile.childImageSharp.fluid}
											alt={field.FirstImage.alternativeText}
											title={field.FirstImage.caption}
										/>
									)}
									{field.SecondImage.imageFile.childImageSharp === null ? (
										<img
											loading="lazy"
											className={field.DoubleDropShadow ? 'dropShadow ' : ''}
											src={field.SecondImage.imageFile.publicURL}
											alt={field.SecondImage.alternativeText}
											title={field.SecondImage.caption}
										/>
									) : (
										<Img
											className={field.DoubleDropShadow ? 'dropShadow ' : ''}
											fluid={field.SecondImage.imageFile.childImageSharp.fluid}
											alt={field.SecondImage.alternativeText}
											title={field.SecondImage.caption}
										/>
									)}
								</div>
							)
						} else if (field.Layout === 'MobileSlider') {
							return (
								<div key={index + 'slider'} className="slider">
									<Slider {...settings}>
										{field.SliderImage1.childImageSharp === null ? (
											<div>
												<img
													loading="lazy"
													src={field.SliderImage1.imageFile.publicURL}
													alt={field.SliderImage1.alternativeText}
													title={field.SliderImage1.caption}
												/>
											</div>
										) : (
											<div>
												<Img
													fluid={
														field.SliderImage1.imageFile.childImageSharp.fluid
													}
													alt={field.SliderImage1.alternativeText}
													title={field.SliderImage1.caption}
												/>
											</div>
										)}
										{field.SliderImage2.imageFile.childImageSharp === null ? (
											<div>
												<img
													loading="lazy"
													src={field.SliderImage2.imageFile.publicURL}
													alt={field.SliderImage2.alternativeText}
													title={field.SliderImage2.caption}
												/>
											</div>
										) : (
											<div>
												<Img
													fluid={
														field.SliderImage2.imageFile.childImageSharp.fluid
													}
													alt={field.SliderImage2.alternativeText}
													title={field.SliderImage2.caption}
												/>
											</div>
										)}
										{field.SliderImage3.imageFile.childImageSharp === null ? (
											<div>
												<img
													loading="lazy"
													src={field.SliderImage3.imageFile.publicURL}
													alt={field.SliderImage3.alternativeText}
													title={field.SliderImage3.caption}
												/>
											</div>
										) : (
											<div>
												<Img
													fluid={
														field.SliderImage3.imageFile.childImageSharp.fluid
													}
													alt={field.SliderImage3.alternativeText}
													title={field.SliderImage3.caption}
												/>
											</div>
										)}
									</Slider>
								</div>
							)
						} else {
							console.log('missing piece: ', field)
							return true
						}
					})}
				</SingleFlexibleContent>
			</SingleContent>
		</Layout>
	)
})

export default Project

export const pageQuery = graphql`
	query Project($id: ID!) {
		timacum {
			project(id: $id) {
				id
				Type
				Title
				Client
				Text
				Year
				GradientDark
				GradientLight
				Tags {
					Title
					id
				}
				Seo {
					MetaDescription
					TitleTag
					Url
				}
				FeaturedImage {
					url
					alternativeText
					caption
					imageFile {
						publicURL
						childImageSharp {
							fluid(maxWidth: 1920, quality: 100) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
				Fields {
					... on TIMACUM_ComponentContentTitleText {
						id

						Layout
						Text
						Title
					}

					... on TIMACUM_ComponentContentImage {
						id
						Layout
						Image {
							url
							alternativeText
							caption
							imageFile {
								publicURL
								childImageSharp {
									fluid(maxWidth: 1920, quality: 100) {
										...GatsbyImageSharpFluid
									}
								}
							}
						}
						SingleDropShadow
						HaveMobileSlider
					}

					... on TIMACUM_ComponentContentMobileSlider {
						id
						Layout
						SliderImage1 {
							url
							alternativeText
							caption
							imageFile {
								publicURL
								childImageSharp {
									fluid(maxWidth: 1920, quality: 100) {
										...GatsbyImageSharpFluid
									}
								}
							}
						}
						SliderImage2 {
							url
							alternativeText
							caption
							imageFile {
								publicURL
								childImageSharp {
									fluid(maxWidth: 1920, quality: 100) {
										...GatsbyImageSharpFluid
									}
								}
							}
						}
						SliderImage3 {
							url
							alternativeText
							caption
							imageFile {
								publicURL
								childImageSharp {
									fluid(maxWidth: 1920, quality: 100) {
										...GatsbyImageSharpFluid
									}
								}
							}
						}
					}

					... on TIMACUM_ComponentContentText {
						id
						Layout
						Text
					}

					... on TIMACUM_ComponentContentCta {
						id
						Layout
						CTA
						Link
					}

					... on TIMACUM_ComponentContentImageRightText {
						id
						Layout
						Text
						Title
						Image {
							url
							alternativeText
							caption
							imageFile {
								publicURL
								childImageSharp {
									fluid(maxWidth: 1920, quality: 100) {
										...GatsbyImageSharpFluid
									}
								}
							}
						}
					}

					... on TIMACUM_ComponentContentImageContentRight {
						id
						Layout
						Text
						Title
						Image {
							url
							alternativeText
							caption
							imageFile {
								publicURL
								childImageSharp {
									fluid(maxWidth: 1920, quality: 100) {
										...GatsbyImageSharpFluid
									}
								}
							}
						}
					}

					... on TIMACUM_ComponentContentImageContentLeft {
						id
						Layout
						Text
						Title
						Image {
							url
							alternativeText
							caption
							imageFile {
								publicURL
								childImageSharp {
									fluid(maxWidth: 1920, quality: 100) {
										...GatsbyImageSharpFluid
									}
								}
							}
						}
					}

					... on TIMACUM_ComponentContentTestemonial {
						id
						ClientName
						Text
						Layout
						ClientImage {
							url
							alternativeText
							caption
							imageFile {
								publicURL
								childImageSharp {
									fluid(maxWidth: 1920, quality: 100) {
										...GatsbyImageSharpFluid
									}
								}
							}
						}
					}

					... on TIMACUM_ComponentContentTwoImages {
						id
						DesktopMobilePreview
						DoubleDropShadow
						Layout
						FirstImage {
							url
							alternativeText
							caption
							imageFile {
								publicURL
								childImageSharp {
									fluid(maxWidth: 1920, quality: 100) {
										...GatsbyImageSharpFluid
									}
								}
							}
						}
						SecondImage {
							url
							alternativeText
							caption
							imageFile {
								publicURL
								childImageSharp {
									fluid(maxWidth: 1920, quality: 100) {
										...GatsbyImageSharpFluid
									}
								}
							}
						}
					}
				}
			}
		}
	}
`
