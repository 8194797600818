import React from 'react'
import ReactPlayer from 'react-player'
import styled from 'styled-components'

const Video = React.memo(props => {
	const { url } = props

	return (
		<Wrapper
			{...props}
			as={ReactPlayer}
			url={url}
			controls={false}
			width={'100%'}
			height={'100%'}
		/>
	)
})

const Wrapper = styled.div`
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 30px;
	height: 0;
	overflow: hidden;
	margin-bottom: 200px;

	iframe,
	object,
	embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	@media (max-width: 525px) {
		margin-bottom: 100px;
	}
`

export default Video
