import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

// Ui components
import Paragraph from '../Paragraph'
import Wysiwyg from '../Wysiwyg'

const Testemonial = React.memo((props) => {
	const { ClientImage, Text, ClientName } = props.fields

	return (
		<Flex {...props}>
			<Column className="irc-content">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="64.001"
					height="51"
					viewBox="0 0 64.001 51"
				>
					<path
						id="quote-icon"
						d="M8.355,35.14v25.5h25.6V35.14h-12.8s0-12.75,12.8-12.75V9.64s-25.6,0-25.6,25.5Zm64-12.75V9.64s-25.6,0-25.6,25.5v25.5h25.6V35.14h-12.8S59.555,22.39,72.355,22.39Z"
						transform="translate(72.355 60.64) rotate(180)"
						opacity="0.2"
					/>
				</svg>
				<TWrapper>
					{/\r|\n/.exec(Text) ? (
						<Wysiwyg>{Text}</Wysiwyg>
					) : (
						<Paragraph>{Text}</Paragraph>
					)}
				</TWrapper>
				<ClientP as={Paragraph}>{ClientName}</ClientP>
			</Column>
			<Column className="content-item">
				<Iwrapp>
					{ClientImage.imageFile.childImageSharp === null ? (
						<img
							loading="lazy"
							src={ClientImage.imageFile.publicURL}
							alt={ClientImage.alternativeText}
							title={ClientImage.caption}
						/>
					) : (
						<Img
							fluid={ClientImage.imageFile.childImageSharp.fluid}
							alt={ClientImage.alternativeText}
							title={ClientImage.caption}
						/>
					)}
				</Iwrapp>
			</Column>
		</Flex>
	)
})

const Flex = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
	margin-bottom: 240px;

	@media (max-width: 1024px) {
		margin-bottom: 12.8em;
	}

	@media (max-width: 525px) {
		margin-bottom: 9em;
	}
`
const Column = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;

	&:nth-of-type(odd) {
		flex-basis: 54.6%;
		max-width: 54.6%;
		display: flex;
		align-items: flex-start;
		padding-right: 128px;
	}

	&:nth-of-type(even) {
		display: flex;
		flex-basis: 46.4%;
		max-width: 45.4%;

		.gatsby-image-wrapper {
			max-height: 830px;
			max-width: 550px;
		}
	}

	@media (max-width: 1250px) {
		&:nth-of-type(odd) {
			flex-basis: 100%;
			max-width: 100%;
			padding-right: 0;
			margin-bottom: 4.8em;
		}

		&:nth-of-type(even) {
			flex-basis: 100%;
			max-width: 100%;

			.gatsby-image-wrapper {
				/* max-height: 553px; */

				& > div {
					/* padding-bottom: 30% !important; */
				}
			}
		}
	}
`

const TWrapper = styled.div`
	p,
	span {
		font-style: italic;
	}
`
const ClientP = styled.div`
	margin-top: 64px;
`

const Iwrapp = styled.div.attrs({
	className: 'image-wrapp',
})`
	position: relative;
	width: 100%;

	&:after {
		/* content: ''; */
		background-color: ${({ theme }) => theme.slider};
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transition: transform 1.25s cubic-bezier(0.475, 0.425, 0, 0.995);
		transform-origin: top;
		transform: scale(1);
	}

	.gatsby-image-wrapper {
		/* opacity: 0;
		transform: translate3d(0, 100px, 0); */
		transition: all 4s cubic-bezier(0.19, 1, 0.22, 1);
		position: relative;
	}

	&.show {
		.gatsby-image-wrapper {
			opacity: 1;
			transform: translateZ(0);
		}

		&:after {
			transform: scaleY(0);
		}
	}
`

export default Testemonial
